import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import React from 'react';
import ContactBlock from "src/components/ContactBlock";

const Footer = ({ companyName }) => (
  <footer className="w-full px-4 py-6 shadow-sm bg-gray-100">
    <div className="container">
      <div className="flex flex-col items-center justify-between my-4 sm:flex-row">
        <ContactBlock compact={true}/>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  companyName: PropTypes.string,
};

Footer.defaultProps = {
  companyName: ``,
};

export default Footer;
