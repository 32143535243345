import { IoMail, IoLogoFacebook, IoMapOutline } from "react-icons/io5";
import { HiOutlinePhone } from "react-icons/hi";
import React from "react";
export default ({compact = false}) => {
    return (
        <div className={"border-b-2 border-secondary mb-5 pb-5"}>
            <span className={"font-black text-primary"}>WARELMA</span> <br/>
            Noordland 5, <br/>
            2040 Zandvliet (Antwerpen) <br/>
            <div className="flex items-center mt-2 text-secondary">
                <HiOutlinePhone size={30}/><a className="ml-2 " href={"tel:0475897245"}>0475897245</a> <br/>
            </div>
            <div className="flex items-center text-secondary">
                <IoMail size={30}></IoMail> <a className="ml-2 " href="mailto:warelma@telenet.be">warelma@telenet.be</a>
            </div>
            {!compact &&
            <div className="flex items-center content-between mt-5">

                <a className="mr-2 text-secondary"
                   href={"https://www.facebook.com/groups/163567891602095"}><IoLogoFacebook
                    size={30}></IoLogoFacebook> </a>
                <a className="mr-2 text-secondary" target="_blank"
                   href="https://www.google.com/maps/dir//Noordland+5A,+2040+Antwerpen">
                    <IoMapOutline size={30}></IoMapOutline>
                </a>
            </div>
            }
        </div>
        )

}